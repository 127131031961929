<template>
    <b-card no-body>
        <b-card-header class="align-items-baseline">
            <div>
                <b-card-title class="mb-25">
                    حجم معاملات
                </b-card-title>
            </div>
        </b-card-header>

        <b-card-body class="pb-0">

            <!-- apex chart -->
            <vue-apex-charts
                    type="line"
                    height="240"
                    :options="salesLine.chartOptions"
                    :series="salesLine.series"
                    :key="test"
            />
        </b-card-body>
    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        // BCardText,
        // BButton,
        // BButtonGroup,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import {$themeColors} from '@themeConfig'

    export default {
        name: 'LineChart',
        components: {
            VueApexCharts,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardBody,
        },
        props: ['chart','sum'],
        data() {
            return {
                salesLine: {
                    series: [
                        {
                            name: ' - تومان ',
                            data: [],
                        },

                    ],
                    chartOptions: {
                        chart: {
                            toolbar: {show: false},
                            zoom: {enabled: false},
                            type: 'line',
                            dropShadow: {
                                enabled: true,
                                top: 18,
                                left: 2,
                                blur: 5,
                                opacity: 0.2,
                            },
                            offsetX: -10,
                            fontFamily: 'Yekan'
                        },
                        stroke: {
                            curve: 'smooth',
                            width: 4,
                        },
                        grid: {
                            borderColor: '#ebe9f1',
                            padding: {
                                top: -20,
                                bottom: 5,
                                left: 20,
                            },
                        },
                        legend: {
                            show: false,
                        },
                        colors: ['#f2ed87'],
                        fill: {
                            type: 'gradient',
                            gradient: {
                                shade: 'dark',
                                inverseColors: false,
                                gradientToColors: [$themeColors.warning],
                                shadeIntensity: 1,
                                type: 'horizontal',
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100, 100, 100],
                            },
                        },
                        markers: {
                            size: 0,
                            hover: {
                                size: 5,
                            },
                        },
                        xaxis: {
                            labels: {
                                rotate: -60,
                                rotateAlways: true,
                                offsetY: 40,
                                offsetX: -10,
                                style: {
                                    colors: '#b9b9c3',
                                    fontSize: '0.857rem',
                                    fontFamily: 'Yekan'
                                },
                            },
                            axisTicks: {
                                show: false,
                            },
                            categories: [],
                            axisBorder: {
                                show: false,
                            },
                            tickPlacement: 'on',
                        },
                        yaxis: {
                            tickAmount: 5,
                            labels: {
                                style: {
                                    colors: '#b9b9c3',
                                    fontSize: '0.857rem',
                                    fontFamily: 'Yekan'
                                },
                                formatter(val) {
                                    return val.toLocaleString()
                                },
                            },
                        },
                        tooltip: {
                            x: {show: false},
                        },
                    },
                },
                test:0,
            }
        },
        watch:{
            'chart'(val){
                let chart = []
                let xaxis = []
                let sum = 0
                for(let a of val){
                    chart.push(a.feeSum)
                    xaxis.push(this.$jmoment(a.date, this.$dateFormat['enDate']).format(this.$dateFormat['faDate']))
                    sum +=  parseFloat(a.feeSum)
                }
                this.$emit('update:sum',sum)
                this.salesLine.chartOptions.xaxis.categories = xaxis
                this.salesLine.series = [{
                    name: this.getChartFeeName(this.chart),
                    data: chart,
                }]
                this.test++
            },
           
        },
        methods:{
                getChartFeeName(chart) {
                    let name = ' - تومان '
                    if(chart[0] && chart[0].coin) {
                        name = this.$coinLabel[chart[0].coin]
                    }
                    return name
                }
        },
        mounted() {
        
            let chart = []
            let xaxis = []
            let sum = 0
            for(let a of this.chart){
                chart.push(a.feeSum)
                xaxis.push(this.$jmoment(a.date, this.$dateFormat['enDate']).format(this.$dateFormat['faDate']))
                sum +=  parseFloat(a.feeSum)
            }
            this.$emit('update:sum',sum)
            this.salesLine.chartOptions.xaxis.categories = xaxis
            console.log(this.chart)
            this.salesLine.series = [{
                name: this.getChartFeeName(this.chart),
                data: chart,
            }]
            this.test++
        }
    }
</script>
